import en from './server/backend/locales/en-US.json'
import de from './server/backend/locales/de-DE'
import type { I18nOptions } from '@nuxtjs/i18n/dist/runtime/composables';
import { useActiveUser } from "./utilities/data/activeUser";
import { serverStrapi } from "./utilities/strapi/serverStrapi";


const alternativeParser = (local: string, key: string) => {
    let config = I18nConfig.messages?.[local] as any;
    if (!config) return;
    let items = key.split('.');
    while (items.length) {
        let item = items.shift();
        if (!item) return;
        if (config?.[item] && ((items.length === 0 && typeof config[item] === 'string') || (items.length && typeof config[item] === 'object'))) {
            config = config[item];
        } else if (config?.[`_${item}`]) {
            config = config[`_${item}`];
        } else if (item === 'undefined') {
            return ''
        } else {
            return;
        }
    }
    return config;
}

export const missing = (local: string, key: string) => {
    if (key === "") return key;

    let translation = alternativeParser(local, key);
    if (translation) return translation;
    addMissing(local, key);
    return key;
}



export const I18nConfig: I18nOptions = {
    legacy: false,
    locale: 'de',
    messages: {
        en,
        de
    },
    // Suppresses warnings for missing translations.
    silentTranslationWarn: true,

    // Suppresses warnings for fallback translations.
    fallbackWarn: false,

    // Suppresses warnings for missing translation files.
    missingWarn: false,
    missing,

}


let missingKeys: { [k: string]: { local: string, key: string, amount: number } } = {};
let saveTime: NodeJS.Timeout | undefined = undefined;
const addMissing = async (local: string, key: string) => {
    missingKeys[key] = missingKeys[key] || { local, key, amount: 0 };
    missingKeys[key].amount++;


    if (!saveTime) {
        saveTime = setTimeout(saveMissing, 4000);
    }
}

const saveMissing = async () => {
    saveTime = undefined;
    let user = useActiveUser();
    if (user.isLoggedIn) {
        serverStrapi('create', 'missingtranslation/set', { data: missingKeys })
        missingKeys = {};
    }
}



export default defineI18nConfig(() => {
    return I18nConfig;
})